<!--<Primeng-TurboTable-Start>-->
<div class="card">
        <div class="mz-container primeng-datatable-container" [busyIf]="primengTableHelper.isLoading || primengTableHelper.isSettingsLoading" class="mb-0">
        <p-table
            #dataTable
            [value]="primengTableHelper.records"
            [paginator]="false"
            [scrollDirection]="scrollDirection"
            [resizableColumns]="isResizable"
            [columns]="columnDefinitions"
            (onRowExpand)="handleRowExpand($event)"
            [styleClass]="scrollDirection !== 'horizontal' ? 'fixed-table-layout' : ''"
            [(selection)]="selectedItems"
            [columnResizeMode]="'none'"
            dataKey="{{ dataKey }}"
            withUnsort
            withSortDelay
            (onColResize)="handleColResize($event)"
            [tableStyle]="{ 'min-width': scrollWidth }"
            [scrollable]="scrollable || isPaginationVisible"
            [scrollHeight]="scrollHeight ? scrollHeight : primengTableHelper.records?.length !== 0 ? '70vh' : '7.2rem'"
            [virtualScroll]="isPaginationVisible && !hasSubTableExpansion && !hasRowExpansion"
            [virtualScrollItemSize]="66"
            [rows]="primengTableHelper.records?.length"
            [lazy]="isPaginationVisible && isLazyLoad"
            (onLazyLoad)="getRecords($event)"
        >
            <ng-template pTemplate="header" *ngIf="rerender">
                <tr class="mz-grid-tr">
                    <th scope="col" 
                        *ngIf="hasRowExpansion || hasSubTableExpansion" 
                        class="mz-grid-th" 
                        style="width: 3.2rem; min-width: 3rem"
                        [attr.data-testid]="'mazars-grid-sub-table-expansion'"
                    ></th>
                    <th scope="col" 
                        *ngIf="headerActionItems" 
                        class="mz-grid-th" 
                        style="width: 3rem; min-width: 3rem"
                        [attr.data-testid]="'mazars-grid-header-action-items'"
                    >
                        <mazars-tableHeaderCheckbox></mazars-tableHeaderCheckbox>
                    </th>
                    <ng-container *ngFor="let cd of columnDefinitions; let i">
                        <th
                            #tableHeader
                            *ngIf="isResizable && cd.sortableName == null && lastVisibleColumn?.localizedField !== cd.label"
                            pResizableColumn
                            [hidden]="cd.isColumnHidden"
                            [alignFrozen]="cd.alignFrozen"
                            pFrozenColumn
                            [frozen]="cd.isColumnFrozen"
                            [id]="cd.label"
                            class="mz-grid-th mz-grid-resizable-th"
                            [ngStyle]="{
                                width: cd && cd.width ? cd.width + '%' : defaultColWidth + '%',
                                'min-width': minColWidth ? minColWidth + 'px' : '',
                                'max-width': cd && cd.width ? cd.width + '%' : ''
                            }"
                            [attr.data-testid]="cd.label + '-' + cd.sortableName"
                        >
                            <div class="d-flex flex-row">
                                <div
                                    [ngClass]="truncateHeaderNames ? 'text-truncate overflow-hidden text-nowrap' : ''"
                                    [mazarsTooltip]="cd.label"
                                    [escape]="false"
                                    isEllipsisActive
                                >
                                    <span>{{ cd.label }}</span>
                                </div>
                                <app-mazars-information-icon
                                    *ngIf="cd.headerInformation"
                                    [uid]="'header-information'"
                                    [message]="cd.headerInformation"
                                    [escape]="false"
                                ></app-mazars-information-icon>
                            </div>
                        </th>
                        <th
                            #tableHeader
                            *ngIf="cd.sortableName == null && (!isResizable || lastVisibleColumn?.localizedField === cd.label)"
                            [hidden]="cd.isColumnHidden"
                            [alignFrozen]="cd.alignFrozen"
                            pFrozenColumn
                            [frozen]="cd.isColumnFrozen"
                            [id]="cd.label"
                            class="mz-grid-th"
                            [ngStyle]="{
                                width: cd && cd.width ? cd.width + '%' : 'auto',
                                'min-width': minColWidth ? minColWidth + 'px' : '',
                                'max-width': cd && cd.width ? cd.width + '%' : ''
                            }"
                            [attr.data-testid]="cd.label + '-' + cd.sortableName"
                        >
                            <div class="d-flex flex-row">
                                <div
                                    [ngClass]="truncateHeaderNames ? 'text-truncate overflow-hidden text-nowrap' : ''"
                                    [mazarsTooltip]="cd.label"
                                    [escape]="false"
                                    isEllipsisActive
                                >
                                    <span>{{ cd.label }}</span>
                                </div>
                                <app-mazars-information-icon
                                    *ngIf="cd.headerInformation"
                                    [uid]="'header-information'"
                                    [message]="cd.headerInformation"
                                    [escape]="false"
                                ></app-mazars-information-icon>
                            </div>
                        </th>
                        <th
                            #tableHeader
                            *ngIf="isResizable && cd.sortableName != null && lastVisibleColumn?.localizedField !== cd.label"
                            [hidden]="cd.isColumnHidden"
                            [alignFrozen]="cd.alignFrozen"
                            pFrozenColumn
                            [frozen]="cd.isColumnFrozen"
                            pResizableColumn
                            [id]="cd.label"
                            class="mz-grid-th mz-grid-resizable-th"
                            [pSortableColumn]="cd.sortableName"
                            [ngStyle]="{
                                width: cd && cd.width ? cd.width + '%' : defaultColWidth + '%',
                                'min-width': minColWidth ? minColWidth + 'px' : '',
                                'max-width': cd && cd.width ? cd.width + '%' : ''
                            }"
                            [attr.data-testid]="cd.label + '-' + cd.sortableName"
                        >
                            <div class="d-flex flex-row">
                                <div
                                    [ngClass]="truncateHeaderNames ? 'text-truncate overflow-hidden text-nowrap' : ''"
                                    [mazarsTooltip]="cd.label"
                                    [escape]="false"
                                    isEllipsisActive
                                >
                                    <span>{{ cd.label }}</span>
                                </div>
                                <app-mazars-information-icon
                                    *ngIf="cd.headerInformation"
                                    [uid]="'header-information'"
                                    [message]="cd.headerInformation"
                                    [escape]="false"
                                ></app-mazars-information-icon>
                                <p-sortIcon [field]="cd.sortableName"></p-sortIcon>
                            </div>
                        </th>
                        <th
                            #tableHeader
                            *ngIf="cd.sortableName != null && (!isResizable || lastVisibleColumn?.localizedField === cd.label)"
                            [hidden]="cd.isColumnHidden"
                            [alignFrozen]="cd.alignFrozen"
                            pFrozenColumn
                            [frozen]="cd.isColumnFrozen"
                            [id]="cd.label"
                            class="mz-grid-th"
                            [pSortableColumn]="cd.sortableName"
                            [ngStyle]="{
                                width: cd && cd.width ? cd.width + '%' : 'auto',
                                'min-width': minColWidth ? minColWidth + 'px' : '',
                                'max-width': cd && cd.width ? cd.width + '%' : ''
                            }"
                            [attr.data-testid]="cd.label + '-' + cd.sortableName"
                        >
                            <div class="d-flex flex-row">
                                <div
                                    [ngClass]="truncateHeaderNames ? 'text-truncate overflow-hidden text-nowrap' : ''"
                                    [mazarsTooltip]="cd.label"
                                    [escape]="false"
                                    isEllipsisActive
                                >
                                    <span>{{ cd.label }}</span>
                                </div>
                                <app-mazars-information-icon
                                    *ngIf="cd.headerInformation"
                                    [uid]="'header-information'"
                                    [message]="cd.headerInformation"
                                    [escape]="false"
                                ></app-mazars-information-icon>
                                <p-sortIcon [field]="cd.sortableName"></p-sortIcon>
                            </div>
                        </th>
                    </ng-container>
                    <th
                        *ngIf="headerActionItems == null"
                        id="ActionsColumnHeader"
                        class="mz-grid-th"
                        style="width: 4rem; min-width: 4rem"
                        [hidden]="isActionColumnHidden && isActionColumnHiddenForSubTable"
                        alignFrozen="right"
                        pFrozenColumn
                        [frozen]="scrollDirection === 'horizontal'"
                        [attr.data-testid]="'actions-column-header'"
                    ></th>
                    <th
                        *ngIf="headerActionItems"
                        class="mz-grid-th"
                        style="width: 4rem; min-width: 4rem"
                        [hidden]="isActionColumnHidden && isActionColumnHiddenForSubTable"
                        [attr.data-testid]="'header-action-items'"
                    >
                        <app-mazars-actions-menu
                            *ngIf="selectedItems && selectedItems.length > 0"
                            style="display: flex; justify-content: flex-end"
                            [uid]="'header_actions_btn'"
                            [actionItems]="dynamicHeaderActions"
                            (itemClick)="handleActionItemClick($event, selectedItems)"
                            (onDropdownShow)="handleDropdownActionsShow()"
                            [isDynamic]="hasDynamicActions"
                        ></app-mazars-actions-menu>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-record="$implicit" let-i="rowIndex" let-columns="columnDefinitions" let-expanded="expanded">
                <tr class="mz-grid-tr">
                    <td
                        *ngIf="hasRowExpansion || hasSubTableExpansion"
                        class="mz-grid-td row-expansion"
                        style="width: 3.2rem"
                        [ngClass]="getBackgroundColorClass(record)"
                    >
                        <button
                            *ngIf="!record.rowExpandDisabled"
                            type="button"
                            pButton
                            pRipple
                            [pRowToggler]="record"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                        ></button>
                    </td>
                    <td *ngIf="headerActionItems" class="mz-grid-td" style="width: 3rem" [ngClass]="getBackgroundColorClass(record)">
                        <p-tableCheckbox [value]="record"></p-tableCheckbox>
                    </td>
                    <td
                        class="mz-grid-td"
                        *ngFor="let col of columnDefinitions"
                        [hidden]="col.isColumnHidden"
                        [alignFrozen]="col.alignFrozen"
                        pFrozenColumn
                        [frozen]="col.isColumnFrozen"
                        [ngStyle]="{
                            width: col && col.width ? col.width + '%' : defaultColWidth + '%',
                            'min-width': minColWidth ? minColWidth + 'px' : '',
                            'max-width': col && col.width ? col.width + '%' : '',
                            'background-color': record && record.style == 'warning' ? 'rgba(240,255,0,.15)' : ''
                        }"
                        [ngClass]="getBackgroundColorClass(record)"
                    >
                        <ng-container *ngIf="col.template == null">
                            <span class="p-column-title">{{ col.label }}</span>
                            <span
                                [innerHTML]="getColumnValue(col, record) | safe: securityContext.HTML"
                                class="mz-data-cell-truncate text-truncate overflow-hidden text-nowrap"
                                [mazarsTooltip]="getColumnValue(col, record)"
                                [escape]="false"
                                tooltipPosition="bottom"
                                isEllipsisActive
                            ></span>
                        </ng-container>
                        <ng-container *ngIf="col.template != null">
                            <span class="p-column-title">{{ col.label }}</span>
                            <ng-container
                                [ngTemplateOutlet]="getTemplate(col.template)"
                                [ngTemplateOutletContext]="{ $implicit: record, index: i, field: col.field }"
                            ></ng-container>
                        </ng-container>
                    </td>
                    <td
                        class="mz-grid-td"
                        style="width: 4rem; display: flex; justify-content: flex-end"
                        [ngClass]="getBackgroundColorClass(record)"
                        [hidden]="isActionColumnHidden && isActionColumnHiddenForSubTable"
                        alignFrozen="right"
                        pFrozenColumn
                        [frozen]="scrollDirection === 'horizontal'"
                    >
                        <app-mazars-actions-menu
                            *ngIf="hasActionItems(actionItems, record)"
                            [hidden]="isActionColumnHidden"
                            [uid]="uid + '_' + record[dataKey] + '_actions_btn'"
                            [actionItems]="getActionItems(actionItems, record)"
                            (itemClick)="handleActionItemClick($event, record)"
                        ></app-mazars-actions-menu>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="loadingbody" let-columns="columns">
                <tr style="height: 66px">
                    <td *ngFor="let col of columns; let even = even">
                        <p-skeleton [ngStyle]="{ width: even ? '40%' : '60%' }"></p-skeleton>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-columns>
                <tr style="height: 66px" *ngIf="!primengTableHelper.isLoading && !primengTableHelper.isSettingsLoading">
                    <td [attr.colspan]="columns?.length">
                        {{ l('NoData') }}
                    </td>
                </tr>
            </ng-template>

            <ng-template *ngIf="expansionMethod === ExpansionMethod.RowExpansion" pTemplate="rowexpansion" let-record="$implicit">
                <tr *ngIf="!record.rowExpandDisabled" class="mz-grid-tr">
                    <td [attr.colspan]="getColumnCount()">
                        <ng-container [ngTemplateOutlet]="getTemplate('expandRowTemplate')" [ngTemplateOutletContext]="{ $implicit: record }"></ng-container>
                    </td>
                </tr>
            </ng-template>

            <ng-template *ngIf="expansionMethod === ExpansionMethod.SubTableExpansion" pTemplate="rowexpansion" let-record="$implicit">
                <ng-container *ngFor="let subTableRecord of primengTableHelper.subTableRecords[record[dataKey]]; let i">
                    <tr *ngIf="!record.rowExpandDisabled" class="mz-grid-tr">
                        <td class="mz-grid-td" style="width: 3rem">
                            <!-- placeholder for parents chevron -->
                        </td>
                        <td *ngIf="headerActionItems" class="mz-grid-td" style="width: 3rem">
                            <p-tableCheckbox [value]="subTableRecord"></p-tableCheckbox>
                        </td>
                        <td
                            class="mz-grid-td"
                            *ngFor="let col of columnDefinitions"
                            [hidden]="col.isColumnHidden"
                            [alignFrozen]="col.alignFrozen"
                            pFrozenColumn
                            [frozen]="col.isColumnFrozen"
                            [ngStyle]="{
                                width: col && col.width ? col.width + '%' : defaultColWidth + '%',
                                'min-width': minColWidth ? minColWidth + 'px' : '',
                                'max-width': col && col.width ? col.width + '%' : '',
                                'background-color': subTableRecord && subTableRecord.style == 'warning' ? 'rgba(240,255,0,.15)' : ''
                            }"
                            [ngClass]="getBackgroundColorClass(subTableRecord)"
                        >
                            <ng-container *ngIf="!col.isValueHiddenInSubTable">
                                <ng-container *ngIf="col.template == null">
                                    <span class="p-column-title">{{ col.label }}</span>
                                    <span
                                        [innerHTML]="getColumnValue(col, subTableRecord) | safe: securityContext.HTML"
                                        class="mz-data-cell-truncate text-truncate overflow-hidden text-nowrap"
                                        [mazarsTooltip]="getColumnValue(col, subTableRecord)"
                                        [escape]="false"
                                        tooltipPosition="bottom"
                                        isEllipsisActive
                                    ></span>
                                </ng-container>
                                <ng-container *ngIf="col.template != null">
                                    <span class="p-column-title">{{ col.label }}</span>
                                    <ng-container
                                        [ngTemplateOutlet]="getTemplate(col.template)"
                                        [ngTemplateOutletContext]="{ $implicit: subTableRecord, index: i, field: col.field }"
                                    ></ng-container>
                                </ng-container>
                            </ng-container>
                        </td>
                        <td
                            class="mz-grid-td"
                            style="width: 4rem; display: flex; justify-content: flex-end"
                            [ngClass]="getBackgroundColorClass(subTableRecord)"
                            [hidden]="isActionColumnHiddenForSubTable && isActionColumnHidden"
                            alignFrozen="right"
                            pFrozenColumn
                            [frozen]="scrollDirection === 'horizontal'"
                        >
                            <app-mazars-actions-menu
                                *ngIf="hasActionItems(subTableActionItems, subTableRecord)"
                                [hidden]="isActionColumnHiddenForSubTable"
                                [uid]="uid + '_' + subTableRecord[dataKey] + '_actions_btn'"
                                [actionItems]="getActionItems(subTableActionItems, subTableRecord)"
                                (itemClick)="handleActionItemClick($event, subTableRecord)"
                            ></app-mazars-actions-menu>
                        </td>
                    </tr>
                </ng-container>
            </ng-template>
        </p-table>
    </div>
</div>
<div class="{{ isPaginationVisible ? 'primeng-paging-container grid-paginator' : 'd-none' }}">
    <p-paginator
        [rows]="recordsCountPerPage"
        #paginator
        (onPageChange)="getRecords($event)"
        [totalRecords]="primengTableHelper.totalRecordsCount"
        [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
        [showCurrentPageReport]="true"
        [currentPageReportTemplate]="'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount"
    ></p-paginator>
</div>
<!--<Primeng-TurboTable-End>-->
