import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'mazars-input-long-number',
    templateUrl: './mazars-input-long-number.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsInputLongNumberComponent,
        },
    ],
})
export class MazarsInputLongNumberComponent extends AppComponentBase implements ControlValueAccessor, OnInit {
    @Input({ required: true }) uid: string;
    @Input() placeholder?: string;
    @Input() isDisabled: boolean;
    @Input() padding: string;
    @Input() tooltipText: string;
    @Input() inlineStyle: string;
    @Input() min?: number;
    @Input() max?: number;
    @Input() maxFractionDigits = 0;
    @Input() showFractionDigits = false;
    @Input() showWarning = false;

    @Input() prefix = '';
    @Input() suffix = '';
    @Output() onBlur: EventEmitter<void> = new EventEmitter<void>();
    @Output() onInput: EventEmitter<void> = new EventEmitter<void>();

    value: string;
    securityContext = SecurityContext;
    mask: any;
    currentLanguage = abp.localization.currentLanguage.name;
    isPasted = false;

    ngOnInit(): void {
        const mask = this.prefix + 'd' + this.suffix;
        this.mask = {
            mask: mask,
            lazy: false,
            blocks: {
                d: {
                    mask: Number,
                    scale: this.maxFractionDigits,
                    max: this.max,
                    min: this.min,
                    signed: false,
                    thousandsSeparator: getLocaleNumberSymbol(this.currentLanguage, NumberSymbol.CurrencyGroup),
                    padFractionalZeros: this.showFractionDigits,
                    normalizeZeros: false,
                    radix: getLocaleNumberSymbol(this.currentLanguage, NumberSymbol.CurrencyDecimal),
                },
            },
        };
    }

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        this.value = obj?.toString();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onInputChange() {
        this.onChange(this.value);
        if (this.isPasted) {
            this.isPasted = false;
        } else {
            this.showWarning = false;
        }
        this.onInput.emit();
    }

    onBlurChange() {
        this.onTouched();
        this.onBlur.emit();
    }

    onPaste(event: ClipboardEvent) {
        const localSeparator = getLocaleNumberSymbol(this.currentLanguage, NumberSymbol.CurrencyDecimal);
        const pastedNumber = event.clipboardData.getData('text') || 0;
        const fractionDigits = pastedNumber.toString().split(localSeparator)[1];
        this.isPasted = true;
        if (fractionDigits?.length > this.maxFractionDigits) {
            this.showWarning = true;
        } else {
            this.showWarning = false;
        }
    }

    ld(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }
}
