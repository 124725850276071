<p-inputNumber
    id="{{ uid }}"
    name="{{ uid }}"
    [inputStyleClass]="'form-control'"
    [(ngModel)]="value"
    [useGrouping]="useGrouping"
    placeholder="{{ placeholder }}"
    [disabled]="isDisabled || disabled"
    [step]="step ?? 1"
    [min]="min"
    [max]="max"
    [mode]="mode"
    [locale]="locale"
    [currency]="currency"
    [prefix]="prefix"
    [suffix]="suffix"
    [minFractionDigits]="minFractionDigits"
    [maxFractionDigits]="maxFractionDigits"
    [style.width.px]="width"
    [style.height.px]="height"
    [allowEmpty]="true"
    [showButtons]="showButtons"
    (onBlur)="onBlur()"
    (onInput)="changeValue($event.value)"
    [attr.data-testid]="uid"
    [mazarsTooltip]="tooltipText"
    [escape]="false"
    tooltipPosition="bottom"
></p-inputNumber>
