export enum ProcessNames {
    Unknown = 'Unknown',
    EditEvaluationPhase = 'EditEvaluationPhase',
    SubmissionDecisionPhase = 'SubmissionDecisionPhase',
    ReviewDocumentationDecisionPhase = 'ReviewDocumentationDecisionPhase',
    FinishedWithoutSubmission = 'FinishedWithoutSubmission',
    ReviewSubmissionDecisionPhase = 'ReviewSubmissionDecisionPhase',
    EditReportDataPhase = 'EditReportDataPhase',
    ReviewReportDataPhase = 'ReviewReportDataPhase',
    SubmissionPhase = 'SubmissionPhase',
    ManualSubmissionPendingPhase = 'ManualSubmissionPendingPhase',
    WaitingForFeedbackPhase = 'WaitingForFeedbackPhase',
    ErrorFeedback = 'ErrorFeedback',
    SuccessfullyFinished = 'SuccessfullyFinished',
    FinishedExternalReporting = 'FinishedExternalReporting',
}
