import { Component, EventEmitter, forwardRef, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { IUserOrOrganizationUnitDto, IUserOrOrganizationUnitInput } from '@shared/service-proxies/common-interfaces';
import { forkJoin, Subscription } from 'rxjs';
import { IItem } from '../../interfaces/item.interface';

@Component({
    selector: 'app-mazars-access-restriction',
    templateUrl: './mazars-access-restriction.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsAccessRestrictionComponent,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MazarsAccessRestrictionComponent),
            multi: true,
        },
    ],
})
export class MazarsAccessRestrictionComponent extends AppComponentBase implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() isCreate: boolean;
    @Input() submitting: boolean;
    @Input() hasEditPermission: boolean;
    @Input() hasAdminPermission: boolean;
    @Input() isAccessRestrictionActive: boolean;
    @Input() accessRestrictionPossibleUsers: IUserOrOrganizationUnitDto[];
    @Input() selectedAccessRestrictionPossibleUsers: IUserOrOrganizationUnitDto[];
    @Input() customGrantsServiceProxy: any;
    @Output() onChangeDone = new EventEmitter<any>();

    form: FormGroup;
    isDisabled: boolean;
    subscriptions: Subscription[] = [];
    items: IItem<string>[] = [];

    constructor(
        injector: Injector,
        private fb: FormBuilder,
    ) {
        super(injector);

        this.createForm();

        this.subscriptions.push(
            this.form.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            }),
        );
    }

    get value(): any {
        return this.form?.value;
    }

    set value(selectedAccessRestrictionPossibleUsers: IUserOrOrganizationUnitDto[]) {
        const value = {
            accessPermission: selectedAccessRestrictionPossibleUsers?.map((u) => `${u.userOrOrganizationId}_${u.type}`),
        };
        this.form.patchValue(value);
        this.onChange(value);
        this.onTouched();
    }

    ngOnInit(): void {
        if (this.isCreate) {
            this.customGrantsServiceProxy.getAccessRestrictedUsers().subscribe((result) => {
                this.isAccessRestrictionActive = result.isAccessRestrictionActive;
                this.accessRestrictionPossibleUsers = result.userOrOrganizations;
                this.mapAccessRestrictionPossibleUsers(this.accessRestrictionPossibleUsers);

                if (this.isAccessRestrictionActive) {
                    this.form.controls.accessPermission.setValidators([Validators.required]);
                    this.value = [
                        {
                            userOrOrganizationId: this.appSession.userId,
                            type: 'User',
                        },
                    ] as IUserOrOrganizationUnitDto[];
                }
                this.onAccessRestrictionChange(this.form.value.accessPermission);
            });
        } else {
            if (this.isAccessRestrictionActive) {
                this.form.controls.accessPermission.setValidators([Validators.required]);
            }
            this.mapAccessRestrictionPossibleUsers(this.accessRestrictionPossibleUsers);
            this.value = this.selectedAccessRestrictionPossibleUsers;
            this.onAccessRestrictionChange(this.form.value.accessPermission);
        }

        if (!this.hasEditPermission) {
            this.form.get('accessPermission')?.disable();
        } else {
            this.form.get('accessPermission')?.enable();
        }
    }

    onAccessRestrictionChange(accessRestrictionUsersOrOrganizationUnitsIds) {
        if (this.isAccessRestrictionActive) {
            forkJoin({
                sourceOne: this.customGrantsServiceProxy.checkCurrentUserIsInList(this.unmapItemsToInput(this.form.value.accessPermission)),
                sourceTwo: this.customGrantsServiceProxy.checkCurrentUserIsInList(this.unmapItemsToInput(accessRestrictionUsersOrOrganizationUnitsIds)),
            }).subscribe((result) => {
                if (result.sourceOne && result.sourceTwo) {
                    if (result.sourceOne === true && result.sourceTwo === false && !this.hasAdminPermission) {
                        this.message.warn(this.l('PermissionAccessRestriction_DropdownWarning'));
                    }
                }
            });

            let provisionalData = this.unmapSelectedItems(accessRestrictionUsersOrOrganizationUnitsIds);
            this.onChangeDone.emit({
                provisionalData: provisionalData,
                accessRestrictionPossibleUsers: this.accessRestrictionPossibleUsers,
                isAccessRestrictionActive: this.isAccessRestrictionActive,
            });
        } else {
            this.onChangeDone.emit(null);
        }
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { accessRestriction: { valid: false } };
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    private createForm() {
        this.form = this.fb.group({
            accessPermission: [],
        });
    }

    private mapAccessRestrictionPossibleUsers(accessRestrictionPossibleUsers: IUserOrOrganizationUnitDto[]) {
        this.items = accessRestrictionPossibleUsers
            ? accessRestrictionPossibleUsers.map(
                  (userOrOganizationUnit: IUserOrOrganizationUnitDto) =>
                      ({
                          id: `${userOrOganizationUnit.userOrOrganizationId}_${userOrOganizationUnit.type}`,
                          text: userOrOganizationUnit.displayName,
                          source: userOrOganizationUnit.type,
                      }) as IItem<string>,
              )
            : [];
    }

    private unmapSelectedItems(selectedItems) {
        return selectedItems
            ? selectedItems.map((i) => ({ type: i.split('_')[1], userOrOrganizationId: i.split('_')[0] }) as IUserOrOrganizationUnitDto)
            : null;
    }

    private unmapItemsToInput(selectedItems) {
        return selectedItems
            ? selectedItems.map((i) => ({ type: i.split('_')[1], userOrOrganizationId: i.split('_')[0] }) as IUserOrOrganizationUnitInput)
            : null;
    }

    private onChange = (_) => {
        // This is intentional
    };

    private onTouched = () => {
        // This is intentional
    };
}
