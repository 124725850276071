<ng-container [formGroup]="form">
    <div class="mz-container h-100" [attr.data-testid]="uid">
        <div class="mz-card mz-card-insets h-100">
            <div class="mz-card-title">{{ l('ProjectManagement') }}</div>
            <div class="row">
                <div *ngIf="form?.controls?.assignedUser" [formGroup]="form.controls.assignedUser" class="col-12">
                    <div class="form-group">
                        <label for="Workflow_Assigned">{{ l('AssignedUser') }}</label>
                        <app-mazars-skeleton *ngIf="dataLoading || referenceDataLoading" uid="AssignedUser" shape="input"></app-mazars-skeleton>
                        <app-mazars-dropdown
                            *ngIf="!dataLoading && !referenceDataLoading"
                            [uid]="'Workflow_Assigned'"
                            [options]="possibleUsers"
                            formControlName="id"
                            hasEmpty="true"
                            [placeholder]="l('Unassigned')"
                            [handleDeletedEntry]="false"
                            [filter]="true"
                        >
                            <ng-template mazarsTemplate let-item>
                                <app-mazars-assignable-user-select
                                    [uid]="'Workflow_Assigned' + item.id"
                                    [mode]="'icontext'"
                                    [assignedUser]="item"
                                    [isEditable]="false"
                                ></app-mazars-assignable-user-select>
                            </ng-template>
                        </app-mazars-dropdown>
                        <app-mazars-validation-message
                            [uid]="'Assigned'"
                            [key]="'AssignedUser'"
                            [control]="form.controls.assignedUserId"
                            [submitting]="showWarnings"
                        ></app-mazars-validation-message>
                    </div>
                </div>

                <div *ngIf="form?.controls?.plannedStart" class="col-12">
                    <div class="form-group">
                        <div class="form-group">
                            <label for="PlannedStart">{{ l('PlannedStart') }}</label>
                            <app-mazars-skeleton *ngIf="dataLoading || referenceDataLoading" uid="PlannedStart" shape="input"></app-mazars-skeleton>
                            <app-mazars-datepicker
                                *ngIf="!dataLoading && !referenceDataLoading"
                                [uid]="'PlannedStart'"
                                [placeholder]="!isDisabled ? l('Select') : ''"
                                formControlName="plannedStart"
                                [showClear]="true"
                            ></app-mazars-datepicker>
                        </div>
                    </div>
                </div>
                <div *ngIf="form?.controls?.plannedEnd" class="col-12">
                    <div class="form-group">
                        <div class="form-group">
                            <label for="PlannedEnd">{{ l('PlannedEnd') }}</label>
                            <app-mazars-skeleton *ngIf="dataLoading || referenceDataLoading" uid="PlannedEnd" shape="input"></app-mazars-skeleton>
                            <app-mazars-datepicker
                                *ngIf="!dataLoading && !referenceDataLoading"
                                [uid]="'PlannedEnd'"
                                [placeholder]="!isDisabled ? l('Select') : ''"
                                formControlName="plannedEnd"
                                [showClear]="true"
                            ></app-mazars-datepicker>
                        </div>
                    </div>
                </div>
                <div *ngIf="form?.controls?.priority" class="col-12">
                    <div class="form-group">
                        <label for="Priority">{{ l('Priority') }}</label>
                        <app-mazars-skeleton *ngIf="dataLoading || referenceDataLoading" uid="Priority" shape="input"></app-mazars-skeleton>
                        <app-mazars-dropdown
                            *ngIf="!dataLoading && !referenceDataLoading"
                            [uid]="'Priority'"
                            [options]="possiblePriority"
                            formControlName="priority"
                            [placeholder]="!isDisabled ? l('Select') : '-'"
                            hasEmpty="true"
                        >
                            <ng-template mazarsTemplate let-item>
                                {{ item.text }}
                            </ng-template>
                        </app-mazars-dropdown>
                        <app-mazars-validation-message
                            [uid]="'Priority'"
                            [key]="'Priority'"
                            [control]="form.controls.priority"
                            [submitting]="showWarnings"
                        ></app-mazars-validation-message>
                    </div>
                </div>
                <div *ngIf="form?.controls?.criticality" class="col-12">
                    <div class="form-group">
                        <label for="Criticality">{{ l('Criticality') }}</label>
                        <app-mazars-skeleton *ngIf="dataLoading || referenceDataLoading" uid="Criticality" shape="input"></app-mazars-skeleton>
                        <app-mazars-dropdown
                            *ngIf="!dataLoading && !referenceDataLoading"
                            [uid]="'Criticality'"
                            [options]="possibleCriticality"
                            formControlName="criticality"
                            [placeholder]="!isDisabled ? l('Select') : '-'"
                            hasEmpty="true"
                        >
                            <ng-template mazarsTemplate let-item>
                                {{ item.text }}
                            </ng-template>
                        </app-mazars-dropdown>
                        <app-mazars-validation-message
                            [uid]="'Criticality'"
                            [key]="'Criticality'"
                            [control]="form.controls.criticality"
                            [submitting]="showWarnings"
                        ></app-mazars-validation-message>
                    </div>
                </div>
                <div *ngIf="form?.controls?.effort" class="col-12">
                    <div class="form-group">
                        <label for="PlannedEffort">{{ l('PlannedEffort') }}</label>
                        <app-mazars-skeleton *ngIf="dataLoading || referenceDataLoading" uid="PlannedEffort" shape="input"></app-mazars-skeleton>
                        <app-mazars-input-number
                            *ngIf="!dataLoading && !referenceDataLoading"
                            [uid]="'PlannedEffort'"
                            formControlName="effort"
                            min="0"
                            max="300"
                            [placeholder]="!isDisabled ? l('PlannedEffort') : ''"
                        ></app-mazars-input-number>
                        <app-mazars-validation-message
                            [uid]="'PlannedEffort'"
                            [key]="'PlannedEffort'"
                            [control]="form.controls.effort"
                            [submitting]="showWarnings"
                        ></app-mazars-validation-message>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
