<form [formGroup]="form">
    <div class="row">
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="Country">{{ l('Country') }} *</label>
                <app-mazars-dropdown [uid]="uid + '-country'" [options]="countries" [hasEmpty]="true" formControlName="countryCode">
                    <ng-template mazarsTemplate let-item let-styleClass="styleClass">
                        <app-mazars-flag-icon class="mr-2" [countryCode]="item.id" [uid]="uid + '-contry-item-' + item?.id"></app-mazars-flag-icon>
                        <span [attr.data-testid]="uid + '-' + item.id" [ngClass]="styleClass">{{ item.text }}</span>
                    </ng-template>
                </app-mazars-dropdown>
                <app-mazars-validation-message
                    [uid]="uid + '-country'"
                    [key]="'Country'"
                    [control]="form.controls.countryCode"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="PostalCode">{{ l('PostalCode') }}</label>
                <app-mazars-input-text [uid]="uid + '-postal-code'" formControlName="postalCode"></app-mazars-input-text>
                <app-mazars-validation-message
                    [uid]="uid + '-postal-code'"
                    [key]="'PostalCode'"
                    [control]="form.controls.postalCode"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="City">{{ l('City') }}</label>
                <app-mazars-input-text [uid]="uid + '-city'" formControlName="city"></app-mazars-input-text>
                <app-mazars-validation-message
                    [uid]="uid + '-city'"
                    [key]="'City'"
                    [control]="form.controls.city"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="Street">{{ l('Street') }}</label>
                <app-mazars-input-text [uid]="uid + '-street'" formControlName="street"></app-mazars-input-text>
                <app-mazars-validation-message
                    [uid]="uid + '-street'"
                    [key]="'Street'"
                    [control]="form.controls.street"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
        <div class="col-lg-2 col-md-2">
            <div class="form-group">
                <label for="BuildingIdentifier">{{ l('BuildingIdentifier') }}</label>
                <app-mazars-input-text [uid]="uid + '-building-identifier'" formControlName="buildingIdentifier"></app-mazars-input-text>
                <app-mazars-validation-message
                    [uid]="uid + '-building-identifier'"
                    [key]="'BuildingIdentifier'"
                    [control]="form.controls.buildingIdentifier"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
        <div class="col-lg-2 col-md-2">
            <div class="form-group">
                <label for="Floor">{{ l('Floor') }}</label>
                <app-mazars-input-text [uid]="uid + '-floor'" formControlName="floor"></app-mazars-input-text>
                <app-mazars-validation-message
                    [uid]="uid + '-floor'"
                    [key]="'Floor'"
                    [control]="form.controls.floor"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
        <div class="col-lg-2 col-md-2" *ngIf="!partyConfig || partyConfig?.showSuite">
            <div class="form-group">
                <label for="Suite">{{ l('Suite') }}</label>
                <app-mazars-input-text [uid]="uid + '-suite'" formControlName="suite"></app-mazars-input-text>
                <app-mazars-validation-message
                    [uid]="uid + '-suite'"
                    [key]="'Suite'"
                    [control]="form.controls.suite"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="Postbox">{{ l('Postbox') }}</label>
                <app-mazars-input-text [uid]="uid + '-postbox'" formControlName="postbox"></app-mazars-input-text>
                <app-mazars-validation-message
                    [uid]="uid + '-postbox'"
                    [key]="'Postbox'"
                    [control]="form.controls.postbox"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
        <div class="col-lg-6 col-md-6" *ngIf="!partyConfig || partyConfig?.showDistrict">
            <div class="form-group">
                <label for="District">{{ l('District') }}</label>
                <app-mazars-input-text [uid]="uid + '-district'" formControlName="district"></app-mazars-input-text>
                <app-mazars-validation-message
                    [uid]="uid + '-district'"
                    [key]="'District'"
                    [control]="form.controls.district"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
    </div>
</form>
