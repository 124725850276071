export enum FormProcessState {
    EditFormPhase = 'EditFormPhase',
    ReviewFormPhase = 'ReviewFormPhase',
    Completed = 'Completed',
    Unknown = 'Unknown'
}

export enum FormProcessStateGroup {
    Edit = 'Edit',
    Review = 'Review',
    Completed = 'Completed'
}
