import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    Injector,
    ViewEncapsulation,
    ContentChildren,
    QueryList,
    TemplateRef,
    OnInit,
    SecurityContext,
    ViewChildren,
} from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { IActionInfo } from '../mazars-actions-dropdown-menu/action-info';
import { IColumnDefinition } from './grid-interfaces';
import { MazarsTemplateDirective } from '../mazars-template/mazars-template.directive';
import { IBulkOperationServiceProxy } from '@shared/service-proxies/interfaces/IBulkOperationServiceProxy';
import { take } from 'rxjs/operators';
import { CommonGridConfigurationFacade } from '../../state-management/facades/common-grid-configuration.facade';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import { TableStateService } from '@shared/utils/table-state.service';
import { PrimengTableHelper } from '@shared/helpers/PrimengTableHelper';
import {
    IBulkDetailsDto,
    IBulkInput,
    IMazarsColumnDefinitionDto,
    IMazarsColumnDefinitionInput,
    IMazarsGridDefinitionDto,
    IMazarsGridDefinitionInput,
} from '@shared/service-proxies/common-interfaces';

enum ExpansionMethod {
    None,
    RowExpansion,
    SubTableExpansion,
}

@Component({
    selector: 'app-mazars-grid',
    templateUrl: './mazars-grid.component.html',
    styleUrls: ['./mazars-grid.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsGridComponent extends AppComponentBase implements OnInit {
    @ContentChildren(MazarsTemplateDirective, { read: MazarsTemplateDirective })
    templateRefs: QueryList<MazarsTemplateDirective>;

    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    @ViewChildren('tableHeader') tableHeader: QueryList<any>;

    @Input({ required: true }) uid: string;
    @Input() columnDefinitions: IColumnDefinition[];
    @Input() primengTableHelper: PrimengTableHelper | any;
    @Input() actionItems: IActionInfo[] | ((record: any) => IActionInfo[]);
    @Input() headerActionItems: IActionInfo[] | ((record: any) => IActionInfo[]);
    @Input() subTableActionItems: IActionInfo[] | ((record: any) => IActionInfo[]);
    @Input() isActionColumnHidden: boolean;
    @Input() isActionColumnHiddenForSubTable: boolean;
    @Input() hasRowExpansion?: boolean;
    @Input() hasSubTableExpansion?: boolean;
    @Input() dataKey = 'id';
    @Input() bulkOperationService: IBulkOperationServiceProxy;
    @Input() isResizable = false;
    @Input() scrollable = false;
    @Input() scrollDirection = 'vertical';
    @Input() scrollHeight: string;
    @Input() scrollWidth = '70rem';
    @Input() truncateHeaderNames = true;
    @Input() minColWidth: string;
    @Input() isPaginationVisible = true;
    @Input() hasGridSettings = false;
    @Input() gridConfigurationFacade: CommonGridConfigurationFacade;
    @Input() isLazyLoad: boolean;

    @Output() actionItemClick: EventEmitter<IActionInfo> = new EventEmitter<IActionInfo>();
    @Output() getAllRecords = new EventEmitter(true);
    @Output() onRowExpand = new EventEmitter();

    gridDefinitionDto: IMazarsGridDefinitionDto;
    lastVisibleColumn: IMazarsColumnDefinitionDto;
    selectedItems: any[];
    dynamicHeaderActions: IActionInfo[];
    actionItemsCache: Map<any, IActionInfo[]> = new Map<any, IActionInfo[]>();
    hasDynamicActions: boolean;
    securityContext = SecurityContext;
    defaultColWidth = 6;
    rerender = true;
    refreshScroll = true;
    recordsCountPerPage = this.primengTableHelper.defaultRecordsCountPerPage;

    expansionMethod: ExpansionMethod = ExpansionMethod.None;

    protected readonly ExpansionMethod = ExpansionMethod;

    constructor(
        _injector: Injector,
        private tableStateService: TableStateService,
    ) {
        super(_injector);
    }

    ngOnInit(): void {
        if (this.uid) {
            const records = this.tableStateService.getRecordsCount(this.uid);
            if (records) {
                this.recordsCountPerPage = records;
            }
        }
        if (this.headerActionItems) {
            let headerActions = this.headerActionItems as (records: any) => IActionInfo[];
            let items = headerActions({ selectedItems: [], bulkDetails: [] });
            let dynamicActions = items.find((item) => item.operation !== undefined);
            if (dynamicActions) {
                this.hasDynamicActions = true;
            } else {
                this.hasDynamicActions = false;
            }
        }
        if (this.isResizable && this.gridConfigurationFacade) {
            this.subscriptions.push(
                this.gridConfigurationFacade.gridColumnSettings$.subscribe({
                    next: (definition: IMazarsGridDefinitionDto) => {
                        if (definition) {
                            this.gridDefinitionDto = _cloneDeep(definition);
                            this.updateColumnDefinitions();
                            this.primengTableHelper.hideSettingsLoadingIndicator();
                        }
                    },
                }),
            );

            this.subscriptions.push(
                this.gridConfigurationFacade.gridColumnSettingsConfigChanged$.subscribe({
                    next: (config: any) => {
                        if (config.data?.configuration && config.data.configuration.length > 0) {
                            this.gridDefinitionDto = _cloneDeep(config.data);
                            this.updateColumnDefinitions();
                        }
                    },
                }),
            );

            this.subscriptions.push(
                this.gridConfigurationFacade.setGridColumnSettings$.subscribe(() => {
                    if (this.hasGridSettings) {
                        this.primengTableHelper.showSettingsLoadingIndicator();
                        this.gridConfigurationFacade.getGridColumnSettings(this.uid);
                    }
                }),
            );
        }

        if (this.hasRowExpansion && this.hasSubTableExpansion) {
            throw new Error("'hasRowExpansion' and 'hasSubTableExpansion' can not both be true.");
        } else if (this.hasRowExpansion) {
            this.expansionMethod = ExpansionMethod.RowExpansion;
        } else if (this.hasSubTableExpansion) {
            this.expansionMethod = ExpansionMethod.SubTableExpansion;
        }

        this.gridConfigurationFacade?.setSorting(null);
        if (this.hasGridSettings) {
            this.primengTableHelper.showSettingsLoadingIndicator();
            this.gridConfigurationFacade?.getGridColumnSettings(this.uid);
        }

        this.getRecords({
            rows: this.primengTableHelper.defaultRecordsCountPerPage
        });
    }

    updateColumnDefinitions() {
        const visibleCols = this.gridDefinitionDto.configuration?.filter((d) => d.visible === true);
        if (visibleCols.length > 0) {
            const widthSum = visibleCols.map((d) => d.width).reduce((a, b) => a + b);
            if (widthSum < 90) {
                this.columnDefinitions.forEach((definition) => (definition.width = undefined));
            } else {
                this.columnDefinitions.forEach(
                    (definition) => (definition.width = this.gridDefinitionDto.configuration.find((d) => d.localizedField === definition.label)?.width),
                );
            }
            this.lastVisibleColumn = visibleCols[visibleCols?.length - 1];
        }
    }

    getRecords(event: any) {
        if (event.last === undefined) {
            if (event.sortField === '_defaultSortField') {
                event['sortField'] = undefined;
            }
            if (event.sortField) {
                this.gridConfigurationFacade?.setSorting(this.primengTableHelper.getSorting(this.dataTable));
            }
            if (event?.rows) {
                this.tableStateService.setRecordsCount(this.uid, event.rows);
            }
            this.getAllRecords.emit(event);
        }
    }

    reloadPage() {
        this.paginator.changePage(this.paginator.getPage());
    }

    setCurrentPage(currentPage: number, maxResultCount: number) {
        this.paginator._first = (currentPage - 1) * maxResultCount;
        this.paginator.updatePaginatorState();
    }

    getTemplate(identifier: string): TemplateRef<any> {
        for (let ref of this.templateRefs) {
            if (ref.identifier === identifier) {
                return ref.template;
            }
        }
        return null;
    }

    hasActionItems(actionItems: IActionInfo[] | ((record: any) => IActionInfo[]), record: any): boolean {
        return this.getActionItems(actionItems, record)?.some((ai) => ai.visible);
    }

    getActionItems(actionItems: IActionInfo[] | ((record: any) => IActionInfo[]), record: any): IActionInfo[] {
        if (this.isActionColumnHidden && this.isActionColumnHiddenForSubTable) {
            return null;
        }
        if (Array.isArray(actionItems)) {
            return actionItems;
        } else {
            if (this.actionItemsCache.has(record)) {
                return this.actionItemsCache.get(record);
            }
            let items = actionItems(record);
            this.actionItemsCache.set(record, items);
            return items;
        }
    }

    getColumnCount() {
        return this.columnDefinitions.length + (this.expansionMethod != ExpansionMethod.None ? 1 : 0) + (this.isActionColumnHidden ? 0 : 1);
    }

    handleDropdownActionsShow() {
        let headerActions = this.headerActionItems as (records: any) => IActionInfo[];
        let items = headerActions({ selectedItems: this.selectedItems, bulkDetails: [] });
        let ids = this.selectedItems.map((item) => item.id);
        const selectedItemsCount = ids.length || 0;
        this.dynamicHeaderActions = items.filter((item) => item.operation === undefined); // set static actions
        this.dynamicHeaderActions.forEach((item) => {
            if (item.action) {
                item.label += ` (${selectedItemsCount}/${selectedItemsCount})`;
            }
        });
        if (this.hasDynamicActions) {
            this.showDynamicLoader();
            this.bulkOperationService
                .checkBulkOperationsDetails({ ids: ids } as IBulkInput)
                .pipe(take(1))
                .subscribe((result) => {
                    let dynamicActionsToAdd: IActionInfo[] = [];
                    let items = headerActions({ selectedItems: this.selectedItems, bulkDetails: result });
                    result.forEach((result) => {
                        let bulkOperation = items.find((item) => item.operation === result.bulkOperation);
                        if (bulkOperation) {
                            bulkOperation.label = bulkOperation.label + this.getBulkDetails(result.bulkDetails);
                            dynamicActionsToAdd.push(bulkOperation);
                        }
                    });
                    this.hideDynamicLoader();
                    this.dynamicHeaderActions = [...this.dynamicHeaderActions, ...dynamicActionsToAdd];
                });
        }
    }

    handleActionItemClick(event: any, record: any): void {
        this.actionItemClick.emit(<any>{
            visible: event.visible,
            label: event.label,
            actionItem: event,
            record: record,
        });
    }

    handleRowExpand(event: any) {
        this.onRowExpand.emit(event);
    }

    getColumnValue(col: IColumnDefinition, record: any): string {
        let value = this.getPropertyByString(record, col.field);

        if (col.customRenderer != null) {
            value = col.customRenderer(value);
        }

        return value;
    }

    getPropertyByString(object: any, columnField: string) {
        if (!columnField) {
            return object;
        }

        let property: string;
        let properties = columnField.split('.');

        let index = 0;
        for (let i = 0; i < properties.length - 1; i++) {
            index = i;
            property = properties[i];

            let candidate = object[property];
            if (candidate !== undefined) {
                object = candidate;
            } else {
                break;
            }
        }
        return object[properties[index]];
    }

    getBackgroundColorClass(record) {
        if (!record) {
            return '';
        }
        if (record.isArchived) {
            return 'archived-color';
        }
        if (record.style === 'warning') {
            return 'warning-color';
        } else {
            if (record.style === 'error') {
                return 'error-color';
            }
        }
    }

    handleColResize($event: any) {
        const arr = this.tableHeader.toArray().filter((element) => element.nativeElement.hidden === false);
        const tableSize = arr.map((element) => element.nativeElement.clientWidth).reduce((a, b) => a + b);
        const relativeDelta = this.getRelativeSize(tableSize, $event.delta);
        const columns = arr.map((element) => ({
            name: element.nativeElement.id,
            size: this.getRelativeMinSize(tableSize, element.nativeElement.clientWidth),
            sizeInPixels: element.nativeElement.clientWidth,
        }));

        const resizedColIndex = columns.findIndex((c) => c.name === $event.element.id);
        if (resizedColIndex !== -1 && resizedColIndex + 1 <= columns.length) {
            const firstCol = columns[resizedColIndex].size + relativeDelta; // resized col
            const secondCol = columns[resizedColIndex + 1].size - relativeDelta; // next to resized col
            if (firstCol > this.defaultColWidth && secondCol > this.defaultColWidth) {
                columns[resizedColIndex].size = firstCol;
                columns[resizedColIndex + 1].size = secondCol;
            } else {
                let deltaCorrection = 0;
                if (firstCol < this.defaultColWidth) {
                    // if resizing col is too small, set it to minimum width
                    deltaCorrection = this.defaultColWidth - firstCol;
                    columns[resizedColIndex].size = firstCol + deltaCorrection;
                    columns[resizedColIndex + 1].size = secondCol - deltaCorrection;
                } else if (secondCol < this.defaultColWidth) {
                    // if resizing col is too wide, set next col to minimum width
                    deltaCorrection = this.defaultColWidth - secondCol;
                    columns[resizedColIndex].size = firstCol - deltaCorrection;
                    columns[resizedColIndex + 1].size = secondCol + deltaCorrection;
                }
            }
        }

        const definition = this.gridDefinitionDto.configuration.map((definition) => ({
            ...definition,
            width: columns.find((c) => c.name === definition.localizedField)?.size,
        }));
        let input = {
            tableId: this.uid,
            configuration: this.dataToConfig(definition),
        } as IMazarsGridDefinitionInput;
        this.gridConfigurationFacade?.setGridColumnSettings(input);
    }

    refreshTable() {
        this.refreshScroll = false;
        setTimeout(() => this.refreshScroll = true, 0);
    }

    private dataToConfig(definitions: any): IMazarsColumnDefinitionInput[] {
        let input: IMazarsColumnDefinitionInput[] = definitions.map(
            (definition) =>
                ({
                    field: definition?.field,
                    isDefault: definition?.isDefault,
                    visible: definition?.visible,
                    canBeChanged: definition?.canBeChanged,
                    localizationKey: definition?.localizationKey,
                    localizationSource: definition?.localizationSource,
                    width: definition?.width,
                }) as IMazarsColumnDefinitionInput,
        );
        return input;
    }

    private showDynamicLoader() {
        let loader = <IActionInfo>{
            visible: true,
            label: '',
            icon: 'pi pi-spin pi-spinner',
        };
        this.dynamicHeaderActions.push(loader);
    }
    private hideDynamicLoader() {
        this.dynamicHeaderActions = this.dynamicHeaderActions.filter((action) => action.icon !== 'pi pi-spin pi-spinner');
    }

    private getBulkDetails(bulkDetails: IBulkDetailsDto): string {
        return ` (${bulkDetails.possible}/${bulkDetails.total})`;
    }

    private getRelativeSize(tableSize: number, clientWidth: number): number {
        return Math.round((clientWidth / tableSize) * 100);
    }

    private getRelativeMinSize(tableSize: number, clientWidth: number): number {
        const relativeSize = Math.round((clientWidth / tableSize) * 100);
        return relativeSize > this.defaultColWidth ? relativeSize : this.defaultColWidth;
    }
}
