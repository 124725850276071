<mz-dialog
    #submitBulkAccesRestictionsModal
    [title]="l('BulkChangeAccessRestrictions_Popup_Headline')"
    [canSubmit]="canSubmit()"
    [submitButtonLabel]="l('Confirm')"
    (beforeSubmit)="beforeSubmit($event)"
    [uid]="'BulkChangeAccessRestrictions_Popup'"
>
    <form [formGroup]="form" (ngSubmit)="modal.submit()">
        <p class="font-size-h6">
            {{ l('BulkChangeAccessRestrictions_Popup_SubHeadline', details?.total, details?.possible) }}
        </p>

        <app-mazars-access-restriction
            *ngIf="grantsServiceProxy"
            name="accessRestrictions"
            [isCreate]="true"
            [hasEditPermission]="true"
            [hasAdminPermission]="true"
            [isAccessRestrictionActive]="true"
            [customGrantsServiceProxy]="grantsServiceProxy"
            (onChangeDone)="onBulkAccessRestrictionChange($event)"
            formControlName="bulkAccessPermission"
        ></app-mazars-access-restriction>
    </form>
</mz-dialog>
