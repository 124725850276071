<div
    [mazarsTooltip]="!areOptionsVisible && (selectedItem ? selectedItem.text : placeholder ? placeholder : l('Select'))"
    tooltipPosition="bottom"
    [escape]="false"
>
    <p-dropdown
        #dd
        id="{{ uid }}"
        name="{{ uid }}"
        [attr.data-testid]="uid"
        [options]="selectItems"
        [disabled]="isDisabled || disabled"
        [(ngModel)]="selectedItem"
        [virtualScroll]="filter"
        [virtualScrollItemSize]="virtualScrollItemSize"
        placeholder="{{ placeholder ? placeholder : l('Select') }}"
        filterPlaceholder="{{ filterPlaceholder != null && filterPlaceholder != '' ? filterPlaceholder : l('Search...') }}"
        (onBlur)="onBlur()"
        (onChange)="onInputChange()"
        (onFilter)="onFilterChange($event)"
        (onShow)="onShow()"
        (onHide)="onHide()"
        (onClear)="onClear()"
        [styleClass]="'form-control dropdown-overlay-fit'"
        [filter]="filter"
        [showClear]="hasEmpty && showClearOption"
        [appendTo]="appendTo"
        [style]="style"
    >
        <ng-template let-item pTemplate="selectedItem">
            <div *ngIf="!selectedItemTemplate" class="truncate">
                <i *ngIf="item.icon" class="{{ item.icon }} mr-2" style="width: 14px" [style.color]="item.iconColor" aria-hidden="true"></i>
                <span [attr.data-testid]="uid + '-' + item.value.id" [ngClass]="item.styleClass">{{ item.label }}</span>
            </div>
            <ng-container *ngTemplateOutlet="selectedItemTemplate; context: { $implicit: item.value, styleClass: item.styleClass }"></ng-container>
        </ng-template>
        <ng-template let-item pTemplate="item">
            <div *ngIf="!itemTemplate" class="text-truncate overflow-hidden text-nowrap" tooltipPosition="top" [mazarsTooltip]="item.label" isEllipsisActive>
                <i *ngIf="item.icon" class="{{ item.icon }} mr-2" style="width: 14px" [style.color]="item.iconColor" aria-hidden="true"></i>
                <span [attr.data-testid]="uid + '-' + item.value.id" [ngClass]="item.styleClass">{{ item.label }}</span>
            </div>
            <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item.value, styleClass: item.styleClass }"></ng-container>
        </ng-template>
        <ng-template pTemplate="footer" *ngIf="actionItems">
            <div class="p-dropdown-items-wrapper p-dropdown-footer-actions py-1">
                <ul class="p-dropdown-items">
                    <div class="p-element">
                        <li
                            class="p-ripple p-element p-dropdown-item"
                            *ngFor="let actionItem of actionItems as IActionInfo"
                            (click)="actionItem.action(); dd.hide()"
                        >
                            <i *ngIf="actionItem.icon" [class]="actionItem.icon"></i>
                            {{ actionItem.label }}
                        </li>
                    </div>
                </ul>
            </div>
        </ng-template>
    </p-dropdown>
</div>
